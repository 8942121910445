<template>
  <div class="row">
    <div class="col-12">
      <octo-header-button
        :buttons="headerButtons"
        @onNewProviderImport="$router.push({name: 'registries.import.wizard'})"
      />
    </div>
    <div class="col-12">
      <provider-import-datatable
        :key="keyProviderImportDataTable"
        @onProviderInputDetailClick="providerImportDetailClick"
        @onProviderInputDeleteClick="providerImportDeleteClick"
      />
    </div>
  </div>
</template>

<script>

import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import ProviderImportDatatable from "@/pages/Registries/datatables/ProviderImportDatatable";
import {endpoints} from "@/routes/endpoints";
import {MessageBox} from "element-ui";

export default {
  name: "ProviderImportIndexPage",
  components: {ProviderImportDatatable, OctoHeaderButton},
  data() {
    return {
      endpoints: endpoints,
      keyProviderImportDataTable: 0,
      headerButtons: [
        {
          label: 'add',
          onClick: 'onNewProviderImport'
        },
      ]
    }
  },
  methods: {
    providerImportDetailClick: function (data) {
      if (data.status === 'error') {
        this.errors = data.errors;
        this.showModal = true;
        return;
      }
      this.$router.push({name: 'providers.import.show', params: {id: data.id}})
    },

    providerImportDeleteClick: function (data) {
      if (data.status === "completed") return;

      MessageBox({
        title: this.$t('page.delete_confirm_title'),
        message: this.$t('page.delete_confirm_provider_text'),
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        showCancelButton: true,
        type: 'warning'
      })
        .then(() => {
          this.$api.delete(endpoints.PROVIDER_IMPORT_DELETE.replace('{id}', data.id))
            .then(() => {
              this.keyProviderImportDataTable++;
              this.$notify({type: 'success', message: this.$t('notifications.delete_success')});
            })
            .catch(() => {
              this.$notify({type: 'danger', message: this.$t('notifications.delete_error')});
            });
        })
        .catch(() => {
        });
    }
  }
}
</script>

<style scoped>

</style>
