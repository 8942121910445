<template>
  <octo-table
    :enable-store="false"
    :action="endpoints.DATATABLES.providerImport"
    :fields="fields"
    :filters="filters"
    name="ProviderImportDataTable"
    @onResetFilters="resetFilters"
  >
    <template v-slot:status="data">
      <div class="text-uppercase small">{{ data.row.status }}</div>
    </template>

    <template v-slot:created_date="data">
      <div class="text-uppercase small">{{ data.row.created_date | date }}</div>
    </template>

    <template v-slot:created_time="data">
      <div class="text-uppercase small">{{ data.row.created_time }}</div>
    </template>

    <template v-slot:create_lead="data">
      <div class="text-uppercase small"><p v-if="data.row.create_lead">SI</p></div>
    </template>

    <template v-slot:actions="data">
      <div class="d-flex justify-content-end">
        <base-button v-if="showDeleteButton(data.row)"
                     class="mx-1"
                     size="sm"
                     link
                     icon
                     @click="onDeleteAction(data.row)">
          <octo-icon icon="wrong"/>
        </base-button>

        <base-button v-if="data.row.status!=='error'"
                     class="mx-1"
                     size="sm"
                     link
                     icon
                     @click="onDetailAction(data.row)">
          <octo-icon icon="right-arrow"/>
        </base-button>
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import {endpoints} from "@/routes/endpoints";
import OctoIcon from "@/components/octo-icon/OctoIcon";

export default {
  name: "ProviderImportDatatable",
  components: {OctoTable, OctoIcon},
  data() {
    return {
      endpoints: endpoints,
      filters: {},
      fields: [
        {prop: 'status', label: 'status', slot: true, align: 'center'},
        {prop: 'created_date', label: 'created', slot: true, align: 'center'},
        {prop: 'created_time', label: 'time', slot: true, align: 'center'},
        {prop: 'create_lead', label: 'create_lead', slot: true, align: 'center'},
        {prop: 'actions', label: 'actions', slot: true},
      ]
    }
  },
  methods: {
    showDeleteButton: function (data) {
      return data.status === 'error'
        || data.created_date < this.$moment().format("YYYY-MM-DD")
        && data.status !== 'completed';
    },
    onDetailAction: function (data) {
      this.$emit('onProviderInputDetailClick', data);
    },
    onDeleteAction: function (data) {
      this.$emit('onProviderInputDeleteClick', data);
    },
    resetFilters: function () {
      this.filters = {};
    },
  }
}
</script>

<style scoped>

</style>
